/* global sharingImageOverride */
// To enable polyfills per babel docs
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'bootstrap';
import './scss/main.scss';
import $ from 'jquery';

// Set the sharing image that is used for the Pinterest sharing links If one is explicitly set in
// the django template (sharingImageOverride), use it. Otherwise use first img in main element.
if (typeof sharingImageOverride !== 'undefined') {
  window.sharingImage = encodeURIComponent(sharingImageOverride);
} else {
  window.sharingImage = encodeURIComponent(`https://${window.location.host}${$('main img').first().attr('src')}`);
}

$(() => {
  // Disable the subscribe button in footer if email input is blank
  $('#button-subscribe').prop('disabled', $('#input-subscribe').value === '' || typeof $('#input-subscribe').value === 'undefined');
  $('#input-subscribe').keyup((e) => {
    $('#button-subscribe').prop('disabled', e.target.value === '');
  });

  // Enable all popovers and tooltips on the page
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').tooltip();

  // Register a handler for the close "X" in the popovers when we show them
  $(document).on('shown.bs.popover', (e) => {
    const popEle = e.target;
    $('.close-popover').on('click', (e2) => {
      e2.preventDefault();
      $(popEle).popover('hide');
    });
  });
});
